body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'SourceSansPro-Regular';
  src: local('SourceSansPro-Regular'), url(./asset/font/Source_Sans_Pro/SourceSansPro-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Signika-Regular';
  src: local('Signika-Regular'), url(./asset/font/Signika-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Signika-Light';
  src: local('Signika-Light'), url(./asset/font/Signika-Light.otf) format('opentype');
}

@font-face {
  font-family: 'Signika-Semibold';
  src: local('Signika-Semibold'), url(./asset/font/Signika-Semibold.otf) format('opentype');
}

@font-face {
  font-family: 'SourceSansPro-Bold';
  src: local('SourceSansPro-Bold'), url(./asset/font/Source_Sans_Pro/SourceSansPro-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Mansalva-Regular';
  src: local('Mansalva-Regular'), url(./asset/font/Mansalva-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Bebas-Neue';
  src: local('Bebas-Neue'), url(./asset/font/BebasNeue-Regular.ttf) format('truetype');
}
